/* eslint-disable */
import { getLayoutData, getPageData} from './api/api';
import {CONTENT_TYPES as COMMON_CONTENT_TYPES, LOCALES} from '@repo/utils/constants';
import { CONTENT_TYPES as GROUP_CONTENT_TYPES } from "@/utils/constants";
import ExtendedContent from "@/components/common/ExtendedContent";
import {StickyHeading} from "@repo/ui";
import Head from "next/head";
import {useRouter} from "next/router";

export default function Home({ data, globals, menu }) {
	const contentData = data?.content || []
	const { locale } = useRouter()
	const canonicalHref = locale === LOCALES.EN ? "https://pleogroup.com/" : "https://pleogroup.com/pl"
	return (
		<>
			<Head>
				<link
					rel="canonical"
					href={canonicalHref}
					hrefLang={locale}
					key="canonical"
				/>
			</Head>
			<StickyHeading stickyHeadingsSelectors={[]} />
			<ExtendedContent content={contentData} locale={locale} globals={globals} isCurrentPageGroup={true} />
		</>
	)
}

const HOME_PAGE_QUERY = `
	query GetHomePageData($locale: SiteLocale, $slug: String) {
		allGroupSinglePages(locale: $locale, filter: {slug: {eq: $slug }}) {
            singlePageName
            slug
            _seoMetaTags {
				attributes
				content
				tag
			}			
			themeLight {
				mainPrimary {
					hex
				}
				mainSecondary {
					hex
				}
				mainTertiary {
					hex
				}
				extraPrimary {
					hex
				}
				extraSecondary {
					hex
				}
				extraTertiary {
					hex
				}
			}
			themeDark {
				mainPrimary {
					hex
				}
				mainSecondary {
					hex
				}
				mainTertiary {
					hex
				}
				extraPrimary {
					hex
				}
				extraSecondary {
					hex
				}
				extraTertiary {
					hex
				}
			}
			content{
				__typename
                ...on ${COMMON_CONTENT_TYPES.VIDEO_BANNER} {
                    id
                    video 
                    banner{
                        variant
                        header{
                            text
                            breaks{
                                image{
                                    url
                                    alt
                                }
                            }
                        }
                        text{
							id
							text{
								text
								breaks{
									id
									image{
										url
										alt
									}
								}
							}
					  	}
                    }
                }
				... on ${GROUP_CONTENT_TYPES.SOCIAL_MEDIA} {
					id
					colorScheme {
						colorScheme
					}
					paddings {
						top
						bottom
					}
					socialMedia {
						title
						link
					}
				}
				... on ${COMMON_CONTENT_TYPES.HORIZONTAL_HEADER} {
					id
					title
					text
					paddings {
						top
						bottom
					}
					colorScheme {
						colorScheme
					}
					transparentBackground
					direction
				}
				... on ${COMMON_CONTENT_TYPES.WHO_WE_WORKED_WITH} {
					id
					header
					description
					bottomText
					paddings {
						top
						bottom
					}
					video {
						url
						alt
					}
					items {
						id
						text
					}
					colorScheme {
						colorScheme
					}
				}
				... on ${COMMON_CONTENT_TYPES.FEATURED_PROJECTS} {
					id
					text
					paddings {
						top
						bottom
					}
					button {
						text
						link {
							... on DigitalAllProjectsPageRecord {
								slug
							}
						}
					}
					projects {
						id
						__typename
						indent
						grid {
							... on ${GROUP_CONTENT_TYPES.SINGLE_DESIGN_PROJECT} {
								__typename
								id
								name
								slug
								description
								highlight
								projectColor {
									hex
								}
								project
								projectCategories {
									projectCategory
									projectCategoryId
								}
								projectServiceRange {
									serviceName
								}
								year
								themeLight {
									mainPrimary {
										hex
									}
									mainSecondary {
										hex
									}
									mainTertiary {
										hex
									}
									extraPrimary {
										hex
									}
									extraSecondary {
										hex
									}
									extraTertiary {
										hex
									}
								}
								themeDark {
									mainPrimary {
										hex
									}
									mainSecondary {
										hex
									}
									mainTertiary {
										hex
									}
									extraPrimary {
										hex
									}
									extraSecondary {
										hex
									}
									extraTertiary {
										hex
									}
								}
								coverPortrait {
									url
								}
								coverLandscape {
									url
								}
								video {
									url
								}
								description
								bannerWhiteLogo
								bannerWhiteMenu
							}
							... on ${GROUP_CONTENT_TYPES.SINGLE_DIGITAL_PROJECT} {
								__typename
								id
								name
								slug
								description
								highlight
								projectColor {
									hex
								}
								project
								projectCategories {
									projectCategory
									projectCategoryId
								}
								projectServiceRange {
									serviceName
								}
								year
								themeLight {
									mainPrimary {
										hex
									}
									mainSecondary {
										hex
									}
									mainTertiary {
										hex
									}
									extraPrimary {
										hex
									}
									extraSecondary {
										hex
									}
									extraTertiary {
										hex
									}
								}
								themeDark {
									mainPrimary {
										hex
									}
									mainSecondary {
										hex
									}
									mainTertiary {
										hex
									}
									extraPrimary {
										hex
									}
									extraSecondary {
										hex
									}
									extraTertiary {
										hex
									}
								}
								coverPortrait {
									url
								}
								coverLandscape {
									url
								}
								video {
									url
								}
								description
								bannerWhiteLogo
								bannerWhiteMenu
							}
						}
					}
			  	}
				... on ${COMMON_CONTENT_TYPES.DESCRIPTION_LOGO_PLEO} {
					id
					header
					description
					button {
						text
						link {
							... on DigitalSinglePageRecord {
								slug
							}
						}
					}
					paddings {
						top
						bottom
					}
			  	}
				... on ${COMMON_CONTENT_TYPES.PLEO_BRANCHES} {
					id
					title
					redirectButtonText
					colorScheme {
						colorScheme
					}
					items {
						id
						link
						subheader
						description
						backgroundColor {
							hex
						}
						logoImage {
							url
							alt
							width
							height
						}
					}
				}
				... on ${COMMON_CONTENT_TYPES.NEWS_THUMBNAILS} {
					header
					button {
						text
						link{
							__typename
							... on DigitalSinglePageRecord{
								slug
							}
							... on GroupSinglePageRecord {
								slug
							}
						}
					}
					chooseNews {
						__typename
						blogPageName
						slug
						_createdAt
						themeLight {
							mainPrimary {
								hex
							}
							mainSecondary{
								hex
							}
						}
						themeDark {
							mainPrimary {
								hex
							}
							mainSecondary{
								hex
							}
						}
						pleoCategory
						categories
						thumbnailImage {
							url
							alt
							width
							height
							focalPoint {
								x
								y
							}
						}
					}
					paddings {
						top
						bottom
					}
				}
				... on ${GROUP_CONTENT_TYPES.ABOUT_TEAM} {
					id
					title
					tagText
					leftText
					boldText
					expandCardButtonText
					collapseCardButtonText
					cards {
						id
						title
						name
						jobTitle
						pictogram {
							url
							alt
						}
						video {
							url
							alt
						}
						aboutTeamSingleCardItems {
							id
							text
							image {
								url
								alt
							}
						}
					}
				}
				... on ${COMMON_CONTENT_TYPES.TEXT_MODULE} {
                    id
                    chooseTextModuleVariants {
                        __typename
                        ... on ${COMMON_CONTENT_TYPES.QUOTE_BLOCK} {
                    quote
                    author
                    id
                    paddings{
                        top
                        bottom
                    }
                    colorScheme{
                        colorScheme
                    }
                }
                ... on ${COMMON_CONTENT_TYPES.TEXT_CENTERED_BLOCK} {
                    text
                    id
                    paddings{
                        top
                        bottom
                    }
                    colorScheme{
                        colorScheme
                    }
                }
                ... on ${COMMON_CONTENT_TYPES.HEADER_DESCRIPTION_TWO_COLUMNS_BLOCK} {
                    header
                    description
                    id
                    paddings{
                        top
                        bottom
                    }
                    colorScheme{
                        colorScheme
                    }
                }
                ... on ${COMMON_CONTENT_TYPES.HEADER_DESCRIPTION_HALF_WIDTH_BLOCK} {
                    header
                    description
                    id
                    paddings{
                        top
                        bottom
                    }
                    colorScheme{
                        colorScheme
                    }
                }
                ... on ${COMMON_CONTENT_TYPES.TEXT_BREAK} {
                    id
                    text
                    colorScheme{
                        colorScheme
                    }
                    paddings {
                        top
                        bottom
                    }
                    breaks {
                        ... on ${COMMON_CONTENT_TYPES.IMAGE_BREAK} {
                    image {
                        url
                    }
                }
                }
                }
                ... on ${COMMON_CONTENT_TYPES.TYPOGRAPHY_BIG} {
                    text
                    paddings{
                        top
                        bottom
                    }
                    colorScheme {
                        colorScheme
                    }
                }
                ... on ${COMMON_CONTENT_TYPES.TYPOGRAPHY_MEDIUM_TEXT} {
							id
							text
							align
							paddings {
								top
								bottom
							}
							colorScheme {
								colorScheme
							}
						}
                }
                }
                ... on ${COMMON_CONTENT_TYPES.CHECK_OTHERS} {
                    id
                    contactHeader
                    checkOthersHeader
                    isColorfulBackground
					text {
					  text {
						text
						breaks {
						  image{
							url
						  }
						}
					  }
					}
                    links {
                        ... on ${GROUP_CONTENT_TYPES.SINGLE_PAGE} {
                            slug
                            name
                        }
                        ... on ${GROUP_CONTENT_TYPES.ALL_PROJECTS_PAGE} {
                            slug
                            name
                        }
					}
					externalLinks {
						text
						url
					}
                }
                ... on ${GROUP_CONTENT_TYPES.SINGLE_ASSET} {
                    id
                    title
                    paddings{
                        top
                        bottom
                    }
                    colorScheme{
                        colorScheme
                    }
                    externalVideoUrl {
                        url
                    }
                    asset {
                        url
                        mimeType
                        id
                        width
                        height
                    }
                    carouselText
                    animation3d
                    assetVariant
                    animationExpandOnScroll
                    backgroundColor {
                      hex
                    }
                    fullScreenVideo{
					  link{
						url
					  }
					}
                }
			}
		}
	}
`;

export async function getStaticProps(context) {
	const locale = context?.locale || 'pl';
	const data = await getPageData('allGroupSinglePages', 'home', HOME_PAGE_QUERY, locale);
	const { globals, cookies, menu, footer } = await getLayoutData(locale)
	return {
		props: {
			data: data,
			globals,
			cookies,
			locale,
			menu,
			footer
		},
	};
}
